<template>
  <BaseFormCard
    :heading="'Book ' + bookingType.title"
    submit-title="Create"
    @submit="create"
  >
    <template #info>
      <h6>Project</h6>
      <ul>
        <li>
          All 'Open' Motion projects for the current user logged in are available in the drop down for selection.
        </li>
        <li>
          The selection box can be typed in to filter the list of available projects.
        </li>
        <li>
          To create a booking for a Non-Motion project, type in the Project box until
          the option 'Press enter for a Non Motion Project' appears and then press enter
          or select this option.
        </li>
      </ul>
      <h6>Type</h6>
      <ul>
        <li>Booking type must be selected before booking dates become available.</li>
        <li>
          Depending on type selected, optional fields may be available.
          These fields can be updated later by the booking requester if required.
        </li>
      </ul>
      <template v-for="f in customDescriptions">
        <h6 :key="f.name + ':title'">
          {{ f.title }}
        </h6>
        <ul :key="f.name + ':lines'">
          <li
            v-for="(line, index) in f.lines"
            :key="index"
          >
            {{ line }}
          </li>
        </ul>
      </template>
      <h6>{{ 'Preferred ' + bookingType.name + ' Date' }}</h6>
      <ul>
        <li>
          Selecting the preferred date will populate the available booking slots
          around this date in the '{{ bookingType.name + ' Date' }}' dropdown list.
        </li>
      </ul>
      <h6>{{ bookingType.name + ' Date' }}</h6>
      <ul>
        <li>
          Select a booking slot and press 'Create' to create the booking.
        </li>
        <li>
          Timeslots are grayed out and not selectable if the booking is full
          or in the past.
        </li>
      </ul>
      <ProjectBookingCreateInfo
        v-if="bookingType.info"
        :info="bookingType.info"
      />
    </template>
    <BaseSelect
      v-if="!projectId"
      v-model="project"
      :options="openProjects"
      :custom-label="projectLabel"
      :taggable="true"
      title="Project"
      name="project"
      track-by="project_id"
      label="project_name"
      tag-placeholder="Press enter for a Non Motion Project"
      required
      @tag="addCustomProject"
    />
    <BaseSelect
      v-model="projectBooking.booking_subtype"
      :options="bookingSubtypes"
      title="Type"
      name="booking_subtype"
      track-by="id"
      label="name"
      required
      @input="getBookings"
    />
    <SchemaPropertyField
      v-for="f in customFields"
      :key="f.name"
      v-model="projectBooking.data[f.name]"
      :name="f.name"
      :schema="f.schema"
      :required="f.required || isReviewFieldRequired(f.name)"
    />
    <BaseInputDate
      v-model="week"
      :display-format="displayWeek"
      :title="'Preferred ' + bookingType.name + ' Date'"
      name="week"
      monday-first
      :disabled="!projectBooking.booking_subtype"
      required
      @input="getBookings"
    />
    <BaseSelect
      v-model="projectBooking.booking"
      :options="bookings"
      :custom-label="customLabel"
      :title="bookingType.name + ' Date'"
      name="bookings"
      track-by="id"
      :disabled="!projectBooking.booking_subtype"
      required
    />
    <BaseInput
      v-if="childDate"
      v-model="childDate"
      title="Review Date"
      name="childDate"
      readonly
    />
    <div
      v-if="isPending"
      class="alert alert-danger"
      role="alert"
    >
      Booking is full, please provide a justification
    </div>
    <BaseTextarea
      v-if="isPending"
      v-model="pending_descr"
      :rows="5"
      title="Justification"
      name="pending_descr"
      required
      trim
    />
    <FieldError v-model="error" />
  </BaseFormCard>
</template>

<script>
import ProjectBookingCreateInfo from '@//views/Booking/ProjectBookingCreateInfo.vue'
import SchemaPropertyField from '@/components/SchemaPropertyField'
import FieldError from '@/components/FieldError'
import bookingAPI from '@/api/booking'
import projectAPI from '@/api/project'
import moment from 'moment'

export default {
  components: {
    ProjectBookingCreateInfo,
    SchemaPropertyField,
    FieldError,
  },
  props: {
    projectId: {
      type: [String, Number],
      default: null,
    },
    bookingTypeId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      bookingType: { name: '', id: '', title: '', info: '' },
      bookingSubtypes: [],
      bookings: [],
      project: null,
      openProjects: [],
      projectBooking: {
        project_id: null,
        project_name: null,
        booking: '',
        booking_subtype: null,
        pending: false,
        data: {},
      },
      pending_descr: '',
      week: moment().format('YYYY-MM-DD'),
      error: '',
    }
  },
  computed: {
    isPendingPrimary () {
      const b = this.projectBooking.booking
      const s = this.projectBooking.booking_subtype
      return Boolean(s && b && b.slots && b.avail_slots <= 0)
    },
    isPendingChild () {
      const b = this.projectBooking.booking
      const s = this.projectBooking.booking_subtype
      return Boolean(s && b && b.child_slots && b.avail_child_slots <= 0)
    },
    isPending () {
      return this.isPendingPrimary || this.isPendingChild
    },
    customFields () {
      const bs = this.projectBooking.booking_subtype
      if (!bs || !bs.schema || !bs.schema.properties) {
        return []
      }
      return Object.keys(bs.schema.properties)
        .map(k => {
          return {
            name: k,
            schema: bs.schema.properties[k],
            required: bs.schema.required && bs.schema.required.includes(k),
          }
        })
        .filter(f => !f.schema.readOnly)
        .sort((a, b) => a.schema['ui:order'] - b.schema['ui:order'])
    },
    customDescriptions () {
      return this.customFields
        .filter(f => f.schema.description && f.schema.description.trim() !== '')
        .map(f => {
          return {
            name: f.name,
            title: f.schema.title,
            lines: f.schema.description.split('\n').map(s => s.trim()).filter(s => !!s),
          }
        })
    },
    reviewFields () {
      const bs = this.projectBooking.booking_subtype
      if (!bs || !bs.schema || !bs.schema['review:required']) {
        return []
      }
      return bs.schema['review:required']
    },
    childDate () {
      return this.formatDate(this.projectBooking.booking.child_start)
    },
    showTime () {
      return !!this.projectBooking.booking_subtype && this.projectBooking.booking_subtype.show_time
    },
  },
  beforeMount () {
    this.getBookingType()
    this.getBookingSubtypes()
  },
  methods: {
    getBookingType () {
      bookingAPI.getBookingType(this.bookingTypeId).then(resp => {
        this.bookingType = resp.data
        this.getOpenProjects(this.bookingType.project_type_id)
      }).catch(error => {
        this.error = error.message
      })
    },
    getBookingSubtypes () {
      bookingAPI.getBookingSubtypes(this.bookingTypeId).then(resp => {
        this.bookingSubtypes = resp.data.filter(t => !t.child)
      }).catch(error => {
        this.error = error.message
      })
    },
    getBookings () {
      this.projectBooking.booking = ''
      if (!this.projectBooking.booking_subtype.id || !this.week) {
        this.bookings = []
        return
      }
      const m = moment(this.week)
      const week = m.isoWeek()
      const year = m.isoWeekYear()
      bookingAPI.getBookings(this.bookingTypeId, year, week).then(resp => {
        const bs = resp.data.filter(b => !b.booking_subtype_id || b.booking_subtype_id === this.projectBooking.booking_subtype.id)
        bs.forEach(element => {
          // check if start time is in the past
          let start = element.start
          if (start.substr(-1) === 'Z') {
            // remove trailing Z so we can use it in local timezone
            start = start.slice(0, -1)
          }
          if (moment().isAfter(start)) {
            element.$isDisabled = true
            return
          }
          // check if the youngest child start time is in the past
          if (element.child_start) {
            start = element.child_start
            if (start.substr(-1) === 'Z') {
              start = start.slice(0, -1)
            }
            if (moment().isAfter(start)) {
              element.$isDisabled = true
              return
            }
          }
          // disable option if no slots (including pending) are available
          if (element.slots && element.avail_slots <= 0 && element.avail_pending_slots <= 0) {
            element.$isDisabled = true
          } else if (element.child_slots && element.avail_child_slots <= 0 && element.avail_child_pending_slots <= 0) {
            element.$isDisabled = true
          }
        })
        this.bookings = bs
      }).catch(error => {
        this.error = error.message
      })
    },
    getOpenProjects (ptid) {
      projectAPI.getGroupProjects(ptid, 'Open').then(resp => {
        this.openProjects = resp.data
      }).catch(error => {
        this.error = error.message
      })
    },
    displayWeek (d) {
      const start = moment(d).startOf('isoWeek')
      const end = moment(start).endOf('isoWeek')
      return start.format('ddd D MMM') + ' - ' + end.format('ddd D MMM')
    },
    formatDate (d) {
      if (!d) { return '' }
      return moment(d).utc().format('ddd D MMM')
    },
    formatTime (b) {
      if (!b.start || !b.finish) {
        return ''
      }
      const ms = moment(b.start).utc()
      const mf = moment(b.finish).utc()
      const st = ms.format('h:mm')
      const ft = mf.format('h:mm')
      let sap = ms.format('a')
      const fap = mf.format('a')
      if (sap === fap) {
        sap = ''
      }
      return st + sap + ' - ' + ft + fap
    },
    customLabel (b) {
      let l = this.formatDate(b.start)
      if (this.showTime) {
        l = l + ' , ' + this.formatTime(b)
      }
      if (b.slots === 1 || b.child_slots === 1 || (!b.slots && !b.child_slots)) {
        return l
      }
      let availSlots
      if (b.slots > 1 && b.child_slots > 1) {
        availSlots = Math.min(b.avail_slots, b.avail_child_slots)
      } else if (b.slots > 1) {
        availSlots = b.avail_slots
      } else {
        availSlots = b.avail_child_slots
      }
      l = l + ' - ' + availSlots + ' ' + this.bookingType.name + ' slots available'
      return l
    },
    projectLabel (p) {
      return p.project_id ? `${p.project_id} - ${p.project_name}` : p.project_name
    },
    addCustomProject (name) {
      // delete old custom record
      const i = this.openProjects.findIndex(p => p.project_id === null)
      if (i >= 0) this.openProjects.splice(i, 1)
      // create new custom record
      this.project = { project_id: null, project_name: name }
      this.openProjects.push({ project_id: null, project_name: name })
    },
    create () {
      this.projectBooking.pending = this.isPending
      if (this.isPending) {
        this.projectBooking.pending_descr = this.pending_descr
      }
      if (this.projectId) {
        this.projectBooking.project_id = parseInt(this.projectId)
      } else if (this.project.project_id) {
        this.projectBooking.project_id = this.project.project_id
      } else {
        this.projectBooking.project_name = this.project.project_name
      }
      bookingAPI.createBooking(this.projectBooking).then(resp => {
        this.$parent.$toasted.success('Project booking created')
        if (this.projectId) {
          this.$emit('reload-project')
        }
        this.$router.go(-1)
      }).catch(error => {
        this.error = error.message
      })
    },
    isReviewFieldRequired (field) {
      return Boolean(this.reviewFields.includes(field) &&
        this.projectBooking && this.projectBooking.booking &&
        this.projectBooking.booking.review_fields_required)
    },
  },
}
</script>

<style scoped>
h6 {
  font-weight: bold;
}
</style>
