<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="booking_create_info"
    v-html="infoMarkdown"
  />
</template>

<script>
import marked from 'marked'

export default {
  props: {
    info: {
      type: String,
      required: true,
    },
  },
  computed: {
    infoMarkdown () {
      return marked(this.info)
    },
  },
}
</script>

<style>
.booking_create_info h6 {
  font-weight: bold;
}
.booking_create_info table {
  width: 100%;
  margin-bottom: 1rem;
  color: #1a202c;
  border: 1px solid #ccc;
}
.booking_create_info thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #ccc;
}
.booking_create_info th, .booking_create_info td {
  padding: 6px 8px;
  border: 1px solid #ccc;
}
</style>
